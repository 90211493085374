const breadcrumbRoutes = [
    // Dashboard
    { path: "/dashboard", breadcrumb: "Dashboard" },

    // Admin User Managament
    { path: "/user-management", breadcrumb: "Admin User Management" },
    { path: "/user-management/:id", breadcrumb: "Detail" },

    // Customer Management
    { path: "/customer-management", breadcrumb: "Customer Management" },
    { path: "/customer-management/add-customer", breadcrumb: "Add Customer" },
    { path: "/customer-management/:id", breadcrumb: "Detail" },

    // License Management
    { path: "/license-management", breadcrumb: "License Management" },
    { path: "/license-management/:id", breadcrumb: "Issue License" },

    // License Reminder
    { path: "/license-reminder", breadcrumb: "License Reminder History" },

    //License Status
    { path: "/license-status", breadcrumb: "License Status" },
];

export default breadcrumbRoutes;
