import { useEffect } from "react";
import { useRouter } from "next/router";
import { Title } from "../components/Title";
import { Page } from "../types/Page";
import { useSession } from "next-auth/react";
import { WithDefaultLayout } from "@/components/DefautLayout";
import { Authorize } from "@/components/Authorize";

const IndexPage: Page = () => {
    const { data: session } = useSession();
    const router = useRouter();

    const isAdmin = session?.user?.["role"][0] == "Administrator";
    useEffect(() => {
        if (session) {
            router.push(isAdmin ? "./dashboard/" : "./license-status/");
        }
    }, [session, isAdmin, router]);

    return (
        <Authorize>
            <div className="w-full">
                <Title>Home</Title>
            </div>
        </Authorize>
    );
};

IndexPage.layout = WithDefaultLayout;
export default IndexPage;
