import { Breadcrumb } from 'antd';
import { useRouter } from 'next/router';
import Link from 'next/link';
import breadcrumbRoutes from '../routes/breadcrumbRoutes';

const generateBreadcrumbs = (pathname: string) => {
    const pathnames = pathname.split('/').filter((x) => x);
    const breadcrumbItems = pathnames.map((value, index) => {
        const url = `/${pathnames.slice(0, index + 1).join('/')}`;
        const route = breadcrumbRoutes.find((route) => new RegExp(`^${route.path.replace(/:[^\s/]+/g, '([^/]+)')}$`).test(url));

        const isCurrentPath = index === pathnames.length - 1;

        return {
            title: (
                <Link href={url}>
                    <span className={isCurrentPath ? 'text-[#3788FD]' : ''}>
                        {route?.breadcrumb}
                    </span>
                </Link>
            )
        };
    });

    return [{ title: 'Licensing Server' }, ...breadcrumbItems];
};

const MyBreadcrumb = () => {
    const router = useRouter();
    const breadcrumbs = generateBreadcrumbs(router.pathname);

    return <Breadcrumb items={breadcrumbs} />;
};

export default MyBreadcrumb;
